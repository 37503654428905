<template>
  <div class="mt20">
    <div class="flex-a-b-c">
      <div class="flex-c-c">
        <el-date-picker
          class="w300"
          v-model="createTime"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-button
          class="v-primary ml10"
          type="primary"
          @click="queryAppointmentPage"
          >查询</el-button
        >
      </div>
      <el-button
          
          class="v-primary ml10"
          type="primary"
          @click="addForm"
          >发布表单</el-button
        >
    </div>

    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="title" align="center" label="表单名称" />
        <el-table-column prop="pubTime" align="center" label="发布时间" />
        <el-table-column prop="endTime" align="center" label="结束时间" />
        <el-table-column prop="feedbackNum" align="center" label="反馈人数"/>
        
        <el-table-column prop="state" align="center" label="操作">
          <template slot-scope="scope"
            ><span
              style="color: #51cbcd; cursor: pointer;margin-right:10px"
              @click="detall_tap(scope.row.advancedFormId)"
            >
              反馈详情
            </span>
            <span
              style="color: #51cbcd; cursor: pointer;margin-right:10px"
              @click="editForm(scope.row.advancedFormId)"
            >
              编辑
            </span>
            <span
              style="cursor: pointer"
              :class="scope.row.status == 1 ? 'corange' : 'cblue'"
              @click="updateStatus(scope.row)"
            >
              {{scope.row.status == 1 ?  '下架' : '上架'}}
            </span>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import {
  updateAdvancedFormStatus,
  fetchProductsTypeList,
  queryPageAdvancedForm
} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";

export default {
  name: "appointmentList",
  components: {
    commonTable,
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      value: "",
      optionList: "", //全部分类
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      createTime:'',
      start: '',
      end: '',
      jurisdiction:''
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      
      this.jurisdiction = val.includes("022")
      console.log(val.includes("022"),'this.jurisdiction')
      console.log(this.jurisdiction,'this.jurisdiction')
    },
    createTime(val,old){
      this.start =  getDataTimeSec(this.createTime[0], 'yy-mm-dd') +" 00:00:00"
      this.end = getDataTimeSec(this.createTime[1], 'yy-mm-dd') +" 23:59:59" 
      
      console.log(this.end)
    },
    
  },
  filters: {
    setState(state) {
      let obj = {
        1: "待确认",
        2: "已确认",
        3: "已完成",
        4: "已取消",
        5: "已过期",
      };
      return obj[state];
    },
  },
  mounted() {
    this.getTableData();
    this.getTypeList();
  },
  methods: {
    //请求预约数据
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        startTime:this.start || '',
        endTime:this.end || ''
      };
      queryPageAdvancedForm(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.pageInfo.list || "";
          this.total = res.data.pageInfo.total;
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    //查询分类
    getTypeList() {
      fetchProductsTypeList({ ignore: true })
        .then((res) => {
          this.optionList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {
      this.currentPage = 1;
      this.pageNum = 1;
      this.getTableData();
      
    },
    //新增表单
    addForm(){
      if(this.jurisdiction || this.userInfo.roleId){
        console.log(this.jurisdiction,'this.jurisdiction')
        this.$router.push(`/publishAdvancedForm`);
      }else{
        this.$message({
            type: "warning",
            message: "暂无权限",
          });
        return
      }
    },
    //编辑表单
    editForm(id){
      if(this.jurisdiction || this.userInfo.roleId){
        console.log(this.jurisdiction,'this.jurisdiction')
        this.$router.push({ path:`/publishAdvancedForm?id=${id}` });
        
      }else{
        this.$message({
            type: "warning",
            message: "暂无权限",
          });
        return
      }
      // if(!this.userInfo.roleId){
      //   this.$message({
      //     type: "warning",
      //     message: "无操作权限",
      //   });
      //   return
      // }
      
    },
    //详情按钮
    detall_tap(id){
      // this.$router.push({
      //   name:'formDetall',
      //   params:{
      //     id:id
      //   }
      // });
      this.$router.push({ path:`/formDetall?id=${id}` });
    },
    //筛选时间
    dateChange(val) {
      this.queryDate = getDataTimeSec(val, "yy-mm-dd");
    },
    //取消
    cancel(val) {
      this.$prompt("请输入取消理由", "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let data = {
            appointmentId: val.appointmentId,
            state: 4,
            applyRemark: value,
          };
          this.changeOrderState(data);
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //确认
    updateStatus(val) {
      if(this.jurisdiction || this.userInfo.roleId){
        this.$confirm( val.status == 0 ? '此表单将会作为一个新的表单发布，其用户历史记录不会跟随移动。' : '点击则下架此表单,并显示结束时间。并且用户端的“高级表单”页面将无内容显示？', "提示", {
          onConfirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let data = {
                advancedFormId : val.advancedFormId,
                status : val.status == 1 ? 0 : 1
              }
          this.changeOrderState(data);
          })
          .catch(() => {});
      }else{
        this.$message({
            type: "warning",
            message: "暂无权限",
          });
        return
      }
      
    },
    // 修改订单状态
    changeOrderState(params) {
      updateAdvancedFormStatus(params)
      .then((res) => {
        this.$message({
          type: "success",
          message: "操作成功！",
        });

        this.getTableData();
      })
      .catch((err)=>{
        if(err.code == 201){
          this.$message({
            type: "warning",
            message: err.message,
          });
        }
        

      });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>